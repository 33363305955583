ew<template>
  <div id="app">
    <input v-model="inputValue" type="number" placeholder="Enter a number">
    <button @click="makePrediction">Predict</button>
    <p v-if="prediction !== null">Prediction: {{ prediction }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      inputValue: '',
      prediction: null
    };
  },
  methods: {
    async makePrediction() {
      try {
        const response = await axios.post('http://47.92.201.40:5000/predict', {
          input: this.inputValue
        });
        this.prediction = response.data.prediction;
      } catch (error) {
        console.error('Error making prediction:', error);
      }
    }
  }
};
</script>
